import React, { useState, useEffect } from 'react';
import HeaderBar from '../../../components/header-bar';
import MemberItem from '../../../components/member-item';
import { navigate } from 'gatsby';
import { useAPI } from '../../../utils/api';
import { showLoading, dismissLoading } from '../../../utils/loading';
import { callAppFunc } from '../../../utils/jsbridge/index.js';
import { pageCheck } from '../../../utils/pageCheck';

const ReceiverList = () => {
  const api = useAPI();
  const [addresses, setAddresses] = useState({});
  const [listUpdate, setListUpdate] = useState(false);

  const deleteAddress = (addressId) => {
    showLoading();
    api
      .deleteAddress({ addressId })
      .then((res) => {
        dismissLoading();
        window.location.reload();
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  };

  useEffect(() => {
    return () => {
      setListUpdate(false);
    };
  }, [listUpdate]);

  useEffect(() => {
    showLoading();
    api
      .getAddress()
      .then((res) => {
        dismissLoading();

        const parsedAddresses = {
          default: [],
          other: [],
        };

        for (const address of res.rows) {
          if (address.isDefault) {
            parsedAddresses.default.push({
              addressId: address.addressId,
              name: address.fullName,
              phone: address.phone,
              address: address.address,
              deliveryIsElevator: address.deliveryIsElevator,
              deliveryPointValue: address.deliveryPointValue,
              deliveryNote: address.deliveryNote,
              isDefault: address.isDefault,
              tag: address.tag,
            });
          } else {
            parsedAddresses.other.push({
              addressId: address.addressId,
              name: address.fullName,
              phone: address.phone,
              address: address.address,
              deliveryIsElevator: address.deliveryIsElevator,
              deliveryPointValue: address.deliveryPointValue,
              deliveryNote: address.deliveryNote,
              isDefault: address.isDefault,
              tag: address.tag,
            });
          }
        }
        setAddresses(parsedAddresses);
        setListUpdate(true);
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  }, []);

  return (
    <>
      <HeaderBar
        title="收件人管理"
        withAddButton
        onAdd={() => {
          pageCheck(api, () => {
            navigate('/member/receiver/add', {
              replace: false,
            });
          });
        }}
        goBack={() => {
          callAppFunc('backHome', {});
        }}
      />
      {listUpdate ? undefined : (
        <div className="receiver-list">
          {addresses.default &&
            addresses.default.map((data, index) => (
              <MemberItem
                data={data}
                isDefault
                key={index}
                onEdit={() => {
                  pageCheck(api, () => {
                    console.log('edit data', data);
                    navigate('/member/receiver/edit', {
                      state: { data: { data } },
                      replace: false,
                    });
                  });
                }}
                onDelete={() => {
                  // if (confirm('是否確定刪除該筆資料？')) {
                  //   deleteAddress(data.addressId);
                  // }
                  confirm('是否確定刪除該筆資料？').then(() => {
                    deleteAddress(data.addressId);
                  });
                }}
              />
            ))}
          {addresses.other &&
            addresses.other.map((data, index) => (
              <MemberItem
                data={data}
                key={index}
                onEdit={() => {
                  pageCheck(api, () => {
                    console.log('edit data', data);
                    navigate('/member/receiver/edit', {
                      state: { data: { data } },
                      replace: false,
                    });
                  });
                }}
                onDelete={() => {
                  // if (confirm('是否確定刪除該筆資料？')) {
                  //   deleteAddress(data.addressId);
                  // }

                  confirm('是否確定刪除該筆資料？').then(() => {
                    deleteAddress(data.addressId);
                  });
                }}
              />
            ))}
        </div>
      )}

      <style jsx>
        {`
          .receiver-list {
            background-color: #f2f2f2;
            padding: 18px;
          }
        `}
      </style>
    </>
  );
};

export default ReceiverList;
